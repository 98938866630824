<!--
* 创建人：邓国伦
* 日 期：
* 描 述：
-->
<!--view 模板-->
<template>
  <div ref="module" class="module">
    <div class="m_left">
      <div class="page-title">公寓楼栋</div>
      <el-tree
        node-key="id"
        v-loading="view.treeloading"
        :data="gyltree"
        :props="{ label: 'text', children: 'childNodes' }"
        :default-expanded-keys="expandkeys"
        :highlight-current="true"
      >
        <div class="custom-tree-node" slot-scope="{ node, data }">
          <span @click="selgyl(node, data)">{{ node.label }}</span>
          <el-dropdown
            v-if="node.level > 1"
            :hide-on-click="false"
            trigger="click"
            @command="handleCommand"
            class="fright"
          >
            <span class="el-dropdown-link">
              <i class="fa fa-ellipsis-v"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="{
                  type: 'addroom',
                  GYQBH: data.parentId,
                  GYLBH: data.id,
                }"
                class="fdarkblue"
              >
                <i class="el-icon-plus"></i>添加房间</el-dropdown-item
              >
              <el-dropdown-item
                class="fcolorff6"
                :command="{ type: 'clear', room: false, GYLBH: data.id }"
                ><i class="el-icon-delete-solid"></i>清空学生</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-tree>
    </div>
    <div ref="m_right" class="m_right">
      <el-row class="page-title">
        <el-col :span="18">
          查询：
          <el-input
            size="small"
            v-model="keyword"
            placeholder="学号/姓名/房间号"
            style="width: 20%"
            class="input-with-select"
            @keydown.enter.native="search"
          >
          </el-input>
          <el-button
            class="ml10"
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="search"
            >搜索</el-button
          >
          <el-button
            class="ml10"
            type="primary"
            size="mini"
            @click="
              view.dialogImportRoomVisible = true
              downerrurl = ''
            "
            >导入房间</el-button
          >
          <el-button
            class="ml10"
            type="primary"
            size="mini"
            @click="
              view.dialogImportVisible = true
              downerrurl = ''
            "
            >导入入住人员</el-button
          >
        </el-col>
        <el-col :span="6" class="tright pr10"> </el-col>
      </el-row>

      <div>
        <el-empty
          v-if="dataPageList.length < 1 && !view.roomloading"
          :description="GYLBH ? '暂无房间' : '暂无房间，请选择公寓楼'"
        ></el-empty>
        <el-table
          v-else
          v-loading="view.roomloading"
          :data="dataPageList"
          :height="windowHeight - 200"
          border
          style="width: 100%"
          header-cell-class-name="tableStyle"
          highlight-current-row
          @current-change="currentRowChange"
          :row-style="{ height: '20px' }"
          :cell-style="{ padding: '5px' }"
        >
          <el-table-column prop="fjbh" label="房间号" width="100">
          </el-table-column>
          <el-table-column prop="xbxz" label="性别限制" width="100">
          </el-table-column>
          <el-table-column prop="lch" label="楼层" width="80">
          </el-table-column>
          <el-table-column prop="cws" label="床位数" width="80">
          </el-table-column>
          <el-table-column label="入住率" width="120">
            <template slot-scope="scope">
              {{ scope.row.xslist.length }}/{{ scope.row.cws }} ({{
                ((scope.row.xslist.length / scope.row.cws) * 100).toFixed(1)
              }}%)
            </template>
          </el-table-column>
          <el-table-column prop="cws" label="入住人员">
            <template slot-scope="scope">
              <el-popover
                v-for="(xs, c) in scope.row.xslist"
                placement="top"
                title="学生信息"
                trigger="click"
                :key="c"
              >
                <div>
                  <p>考生号：{{ xs.ksh }}</p>
                  <p>学号：{{ xs.xh }}</p>
                  <p>姓名:{{ xs.xm }}</p>
                  <p>性别:{{ xs.xbm == '1' ? '男' : '女' }}</p>
                  <p>院系:{{ xs.bmmc }}</p>
                  <p>专业:{{ xs.zymc }}</p>
                  <p>班级:{{ xs.bjmc }}</p>
                </div>
                <el-tag
                  slot="reference"
                  closable
                  size="small"
                  @close="delxs(scope.row, xs)"
                  :type="xs.xm == keyword.trim() ? 'danger' : ''"
                  >{{ xs.xm }}</el-tag
                >
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-link
                type="primary"
                @click="addxs(scope.row)"
                :disabled="scope.row.xslist.length >= scope.row.cws"
                >入住</el-link
              >
              <el-link
                type="warning"
                class="ml10"
                :disabled="!scope.row.xslist.length > 0"
                @click="clearfj(scope.row.fjbh)"
                >清空</el-link
              >
              <el-link
                type="danger"
                class="ml10"
                :disabled="!scope.row.xslist.length < 1"
                @click="deletefj(scope.row.id)"
                >删除房间</el-link
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          hide-on-single-page
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <!--学生选择框-->
    <sy-stbuselect
      :visible="view.stbuvisible"
      @ok="stuok"
      @close="stuclose"
    ></sy-stbuselect>
    <!------------------添加 修改 -------------->
    <el-dialog
      :title="operation == 1 ? '新增' : '编辑'"
      :visible.sync="dialogFormVisible"
      width="40%"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <el-form
        v-loading="view.dialogloading"
        :model="info"
        v-if="dialogFormVisible"
        ref="formRef"
        :rules="infoRules"
      >
        <el-form-item label="公寓楼名称" label-width="120px" prop="GYL">
          <el-cascader
            v-model="info.GYL"
            :options="gyltree"
            :props="{ value: 'id', label: 'text', children: 'childNodes' }"
            @change="handleChange"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="房间编号" label-width="120px" prop="FJBH">
          <el-input
            v-model="info.FJBH"
            autocomplete="off"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item label="楼层" label-width="120px" prop="LCH">
          <el-select v-model="info.LCH" style="display: block">
            <el-option
              v-for="index in LCS"
              :key="index"
              :label="index + '楼'"
              :value="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="房间用途" label-width="120px" prop="FJYT">
          <el-select v-model="info.FJYT" style="display: block">
            <el-option
              v-for="(item, index) in fjytoption"
              :key="index"
              :label="item.lable"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="性别限制" label-width="120px" prop="XBXZ">
          <el-radio v-model="info.XBXZ" label="男">男</el-radio>
          <el-radio v-model="info.XBXZ" label="女">女</el-radio>
        </el-form-item>
        <el-form-item label="床位数" label-width="120px" prop="CWS">
          <el-input-number
            v-model="info.CWS"
            controls-position="right"
            :min="1"
            :max="10"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveForm">确 定</el-button>
      </div>
    </el-dialog>
    <!--导入房间-->
    <el-dialog
      title="导入房间"
      :visible.sync="view.dialogImportRoomVisible"
      top="5vh"
    >
      <div style="text-align: center">
        <el-upload
          class="upload-demo"
          drag
          :headers="headers"
          :action="uploadroomurl"
          :multiple="false"
          :on-success="handleRoomSuccess"
          :show-file-list="false"
        >
          <el-icon class="el-icon-upload">
            <upload-filled />
          </el-icon>
          <div class="el-upload__text">
            <em>点击上传</em>
          </div>
          <template #tip>
            <div class="el-upload__tip">请上传excel文件</div>
          </template>
        </el-upload>
        <div>
          <el-link
            type="primary"
            :href="downbaseurl + 'ExcelTemplate/sg_roommd.xlsx'"
            >模板下载</el-link
          >
          <br />
          <el-link type="danger" v-if="downerrurl" :href="downerrurl"
            >下载错误信息</el-link
          >
        </div>
      </div>
    </el-dialog>
    <!--导入入住名单-->
    <el-dialog
      title="导入入住名单"
      :visible.sync="view.dialogImportVisible"
      top="5vh"
    >
      <div style="text-align: center">
        <el-upload
          class="upload-demo"
          drag
          :headers="headers"
          :action="uploadurl"
          :multiple="false"
          :on-success="handleSuccess"
          :show-file-list="false"
        >
          <el-icon class="el-icon-upload">
            <upload-filled />
          </el-icon>
          <div class="el-upload__text">
            <em>点击上传</em>
          </div>
          <template #tip>
            <div class="el-upload__tip">请上传excel文件</div>
          </template>
        </el-upload>
        <div>
          <el-link
            type="primary"
            :href="downbaseurl + 'ExcelTemplate/sg_xsrzmd.xlsx'"
            >模板下载</el-link
          >
          <br />
          <el-link type="danger" v-if="downerrurl" :href="downerrurl"
            >下载错误信息</el-link
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<!-- javascript脚本-->
<script>
import stuselect from '../../components/dialog/stuselect.vue'
import sg from '../../api/gygl'
import { baseUrl } from '../../utils/http'
import store from '../../store'
export default {
  data() {
    return {
      pagination: {
        rows: 20,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      gyltree: [],
      expandkeys: [],
      roomlist: [], // 房间列表
      dataPageList: [], // 房间分页列表
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      GYLBH: null, // 公寓楼编号
      GYLMC: null, // 公寓楼名称
      keyword: '',
      FJBH: '', // 当前选择的房间编号
      LCS: 0, // 楼层数
      operation: 1, // 当前操作1=添加，0=编辑
      dialogFormVisible: false, // 是否显示弹出框
      uploadurl: baseUrl + 'gygl/SY_SG_GY0104/importstu?dir=temp',
      uploadroomurl: baseUrl + 'gygl/SY_SG_GY0104/importroom?dir=temp',
      downbaseurl: baseUrl,
      headers: '',
      downerrurl: '',
      view: {
        scrollbarheight: 0,
        treeloading: false,
        roomloading: false,
        stbuvisible: false, // 学生选择框
        dialogloading: false,
        dialogImportVisible: false,
        dialogImportRoomVisible: false,
      },
      infoRules: {
        FJBH: [
          {
            required: true,
            trigger: 'blur',
            validator: (r, v, callback) => {
              if (v === '' || v === null) {
                return callback(new Error('房间编号不能为空'))
              } else {
                sg.exfjbh({ id: this.keyValue, bh: v })
                  .then((res) => {
                    if (res.code === 200) {
                      if (res.data.flag === true) {
                        return callback(new Error('编号重复'))
                      } else {
                        callback()
                      }
                    } else {
                      return callback(new Error('编号验证失败'))
                    }
                  })
                  .catch((e) => {
                    return callback(new Error('编号验证失败'))
                  })
              }
            },
          },
        ],
        GYLBH: [{ required: true, message: '请选择公寓楼', trigger: 'blur' }],
        LCH: [{ required: true, message: '请选择公寓区', trigger: 'blur' }],
        CWS: [{ required: true, message: '床位数不能为空', trigger: 'blur' }],
        XBXZ: [{ required: true, message: '请选择性别限制', trigger: 'blur' }],
        FJYT: [{ required: true, message: '请选择房间用途', trigger: 'blur' }],
      },
      info: {
        ID: '',
        GYL: [],
        FJBH: '',
        XBXZ: '',
        GYLBH: '',
        FJYT: '',
        LCH: '',
        CWS: 0,
        BZ: '',
      },
      fjytoption: [
        { lable: '学生住宿', value: '学生住宿' },
        { lable: '教师住宿', value: '教师住宿' },
        { lable: '其他', value: '其他' },
      ],
    }
  },
  created() {
    this.headers = {
      Authorization: 'Bearer ' + store.state.token.access_token,
    }
    this.treeloading = true
    sg.getgyltree().then((res) => {
      this.treeloading = false
      if (res.code === 200) {
        this.gyltree = res.data
        if (this.gyltree.length > 0) {
          this.expandkeys.push(this.gyltree[0].id)
        }
        this.view.scrollbarheight = this.$refs.module.clientHeight - 55
      }
    })
  },
  methods: {
    handleCurrentChange(val) {
      this.pagination.page = val
      this.getpagelist()
    },
    handleChange(val) {
      this.info.GYLBH = val[val.length - 1]
      sg.getgylformdata({ gylbh: this.info.GYLBH }).then((res) => {
        if (res.data == null) {
          this.$message.error('失败，公寓楼不存在或已被删除')
          return false
        }
        this.LCS = res.data.lcs
      })
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
      }
    },
    handleSuccess(response, file, fileList) {
      if (response.code === 200) {
        this.$message.success(response.message)
        this.view.dialogImportVisible = false
        this.getpagelist()
      } else {
        this.$message.error(response.message)
        if (response.data) {
          this.downerrurl = this.downbaseurl + response.data.url
        }
      }
    },
    handleRoomSuccess(response, file, fileList) {
      if (response.code === 200) {
        this.$message.success(response.message)
        this.view.dialogImportRoomVisible = false
        this.getpagelist()
      } else {
        this.$message.error(response.message)
        if (response.data) {
          this.downerrurl = this.downbaseurl + response.data.url
        }
      }
    },
    selgyl(node, nodedata) {
      if (
        node.level > 1 &&
        (nodedata.childNodes == null || nodedata.childNodes.length < 1)
      ) {
        this.GYLBH = nodedata.id
        this.GYLMC = nodedata.text
        this.keyword = ''
        this.getpagelist()
      } else {
        this.GYLBH = null
      }
    },
    getpagelist() {
      var dat = { GYLBH: this.GYLBH }
      if (this.keyword.trim() !== '') {
        dat = { keyword: this.keyword }
      }

      this.view.roomloading = true
      sg.getfjviewpagelist({
        queryJson: JSON.stringify(dat),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.view.roomloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.view.roomloading = false
          }
        })
        .catch((err) => {
          this.dataPageList = []
          this.view.roomloading = false
          console.log(err)
        })
    },
    getDataPageList() {
      this.view.roomloading = true
      var dat = { GYLBH: this.GYLBH }
      if (this.keyword.trim() !== '') {
        dat = { keyword: this.keyword }
      }
      this.roomlist = []
      sg.getfjviewlist({ queryJson: JSON.stringify(dat) }).then((res) => {
        this.view.roomloading = false
        if (res.code === 200) {
          var lc = []
          res.data.forEach((v) => {
            if (!lc.includes(v.lch)) {
              lc.push(v.lch)
            }
          })
          lc.forEach((v) => {
            var item = {
              lcs: v,
              list: res.data.filter((fl) => fl.lch === v),
            }
            this.roomlist.push(item)
          })
        } else {
          this.roomlist = []
        }
      })
    },
    // 房间按钮
    handleCommand(obj) {
      switch (obj.type) {
        case 'addroom':
          this.addroom(obj.GYQBH, obj.GYLBH)
          break
        case 'edit':
          this.editroom(obj.room)
          break
        case 'clear':
          // 清空学生
          if (obj.room) {
            this.clearfj(obj.room.fjbh)
          }
          if (obj.GYLBH) {
            this.clearfj('', obj.GYLBH)
          }
          break
        case 'delete':
          // 删除房间
          this.deletefj(obj.room.id)
          break
      }
    },
    // 删除房间
    deletefj(id) {
      this.$confirm('此操作将删除此房间, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          sg.deletefj({ id: id }).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.getpagelist()
            } else {
              this.$message.error(res.message)
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    // 学生入住
    addxs(room) {
      if (room.fjyt !== '学生住宿') {
        this.$message.error('此房间用途为“' + room.fjyt + '”不能添加学生入住')
        return false
      }
      this.view.stbuvisible = true
      this.FJBH = room.fjbh
    },
    // 移除学生
    delxs(room, xs) {
      this.$confirm('此操作将该学生从此房间删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          var dat = {
            XH: xs.xh,
            KSH: xs.ksh,
            FJBH: room.fjbh,
          }
          sg.delrz(dat).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.getpagelist()
              this.$forceUpdate()
            } else {
              this.$message.error(res.message)
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    // 清空房间
    clearfj(fjbh, gylbh) {
      this.$confirm('确定要进行清空学生操作吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          sg.clearfj({ FJBH: fjbh, GYLBH: gylbh }).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.getpagelist()
            } else {
              this.$message.error(res.message)
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    // 添加房间
    addroom(gyqbh, gylbh) {
      this.GYLBH = gylbh
      this.info.GYL = [gyqbh, gylbh]
      this.operation = 1
      this.view.dialogloading = true
      this.dialogFormVisible = true
      sg.getgylformdata({ gylbh: this.GYLBH }).then((res) => {
        this.view.dialogloading = false
        if (res.data == null) {
          this.$message.error('失败，公寓楼不存在或已被删除')
          this.dialogFormVisible = false
          return false
        }
        this.info.GYLBH = this.GYLBH
        this.LCS = res.data.lcs
      })
    },
    // 修改房间
    editroom(room) {
      this.operation = 2
      this.keyValue = room.id
      this.dialogFormVisible = true
      this.view.dialogloading = true
      sg.getgylformdata({ gylbh: this.GYLBH }).then((res) => {
        this.view.dialogloading = false
        if (res.data == null) {
          this.dialogFormVisible = false
          this.$message.error('失败，公寓楼不存在或已被删除')
          return false
        }
        this.LCS = res.data.lcs
        this.info.GYL = [res.data.gyqbh, res.data.gylbh]
        this.info.ID = room.id
        this.info.FJBH = room.fjbh
        this.info.XBXZ = room.xbxz
        this.info.GYLBH = room.gylbh
        this.info.FJYT = room.fjyt
        this.info.LCH = room.lch
        this.info.CWS = room.cws
        this.info.GYLBH = room.gylbh
      })
    },
    // 保存房间
    saveForm() {
      if (this.operation === 1) {
        this.keyValue = ''
        this.info.ID = ''
      } else {
        this.info.ID = this.keyValue
      }

      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          sg.savefj(this.info).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.dialogFormVisible = false
              this.getpagelist()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.info = JSON.parse(JSON.stringify(this.info))
      })
      this.$refs.formRef.resetFields()
    },
    // 选择学生
    stuok(xs) {
      this.view.stbuvisible = false
      var dat = {
        XH: xs.xh,
        KSH: xs.KSH,
        FJBH: this.FJBH,
      }
      sg.xsrz(dat).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message)
          this.getpagelist()
          this.$forceUpdate()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    stuclose() {
      this.view.stbuvisible = false
      this.FJBH = ''
    },
    search() {
      this.getpagelist()
    },
  },
  components: {
    'sy-stbuselect': stuselect,
  },
  mounted() {
    this.view.scrollbarheight = this.$refs.module.clientHeight - 55
  },
}
</script>
<!-- CSS样式 -->
<style>
.module {
  position: relative;
  padding-left: 210px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
.m_left {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
  background-color: #fff;
  border-radius: 3px;
}
.m_right {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background-color: #fff;
  box-sizing: border-box;
}
.page-title {
  height: 38px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.custom-tree-node {
  width: 100%;
}
.room >>> .box-card {
  margin: 5px;
}
.room .el-col-6 {
  width: 24%;
  margin: 5px;
}
</style>
