<!--
* 创建人：邓国伦
* 日 期：
* 描 述：角色选择
-->
<!--view 模板-->
<template>
  <div>
    <el-dialog
      title="选择学生"
      :visible.sync="visible"
      :close-on-click-modal="true"
      :before-close="close"
      width="40%"
      center
    >
      <!--=============================工具栏========================================-->
      <div class="page-toolbar">
        <el-input
          placeholder="考生号/学号/姓名"
          v-model="keyword"
          class="input-with-select"
          @keyup.enter.native="dataPageList"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="dataPageList"
          ></el-button>
        </el-input>
      </div>
      <!--=============================表格========================================-->
      <div class="c_table mt10">
        <el-table
          v-loading="tableloading"
          :data="dataList"
          :height="300"
          border
          style="width: 100%"
          header-cell-class-name="tableStyle"
          highlight-current-row
          @row-dblclick="ok"
          :row-style="{ height: '20px' }"
          :cell-style="{ padding: '5px' }"
        >
          <!-- <el-table-column prop="bmmc" label="院系" width="200"> </el-table-column>
          <el-table-column prop="zymc" label="专业" width="200"> </el-table-column> -->
          <el-table-column prop="xh" label="学号" width="120">
          </el-table-column>
          <el-table-column prop="xm" label="姓名" width="80"> </el-table-column>
          <el-table-column prop="xbm" label="性别" width="60">
            <template slot-scope="scope">
              {{ scope.row.xbm == 1 ? '男' : '女' }}
            </template>
          </el-table-column>
          <el-table-column prop="fjbh" label="入住房间" width="80"> </el-table-column>
          <el-table-column prop="bjmc" label="班级"> </el-table-column>
          <el-table-column fixed="right" label="选择" width="100">
            <template slot-scope="scope">
              <el-button @click="ok(scope.row)" type="text" size="small"
                >选择</el-button
              >
            </template>
          </el-table-column>
          <!-- <el-table-column prop="zt" label="状态"> </el-table-column> -->
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>
<!-- javascript脚本-->
<script>
import { getXSPageList } from '../../api/jcxx'
export default {
  props: {
    visible: {
      // 显示隐藏
      type: Boolean,
      default: false,
    },
    xb: {
      type: String,
    },
  },
  data() {
    return {
      keyword: '',
      dataList: null,
      tableloading: false,
    }
  },
  methods: {
    dataPageList() {
      if (!this.keyword || this.keyword.trim() === '') {
        this.$message.error('请输入查询考生号/学号/姓名')
        return
      }
      var dat = {
        queryJson: JSON.stringify({ keyword: this.keyword }),
        pagination: JSON.stringify({ rows: 100, page: 1 }),
      }
      this.tableloading = true
      getXSPageList(dat).then((res) => {
        this.tableloading = false
        if (res.code === 200) {
          this.dataList = res.data.rows
        } else {
          this.dataList = []
        }
      })
    },
    ok(row) {
      this.$emit('ok', row)
      // this.$emit("close");
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>
<!-- CSS样式 -->
<style scoped></style>
